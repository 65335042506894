<template>
	<div>
		<h1>{{ question.name }}</h1>
		<p>{{ question.description }}</p>

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7 question-image-container">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">{{ survey.previous }}</button>
			<button type="button" class="btn mx-2" @click="doAnswer">{{ survey.next }}</button>
		</div>
	</div>
</template>

<style scoped>
div >>> p {
	font-size: 1.4rem;
	white-space: pre-line;
}
</style>

<script>
export default {
	name: 'InformationType',
	props: ['survey', 'question', 'isfirstquestion'],
	data() {
		return {
			answers: [],
		};
	},
	computed: {
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
	},
	methods: {
		doAnswer() {
			this.$emit('answer', '');
		},
	},
};
</script>
