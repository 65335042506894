<template>
	<div class="main">
		<div class="d-md-none">
			<h1 class="mt-3">{{ question.option_1 }} <span v-if="question.is_required === true" style="color: darkred;">*</span></h1>
			<p>{{ question.description }}</p>

			<hr class="my-4" />
		</div>
		<div v-for="(subquestion, idx) in questions" class="d-md-none mb-5" :key="'mtqq' + idx + subquestion.id">
			<h1>{{ subquestion.name }}</h1>
			<p>{{ subquestion.description }}</p>

			<div v-for="(answer, index) in subquestion.answers" :key="'mta' + index + answer.id">
				<input v-bind:id="'answer' + idx + '_' + index + '_mobile'" :name="'answer' + idx + '_mobile'" :data-name="'answer' + idx" type="radio" :value="answer.id" :checked="givenAnswer && givenAnswer[idx] == answer.id"/>
<!--				:checked="givenAnswer && givenAnswer.indexOf(answer.id) > -1"-->
				<label v-bind:for="'answer' + idx + '_' + index + '_mobile'">{{ answer.name }}</label>
			</div>
		</div>
		<div class="d-none d-md-block">
			<h1>{{ question.option_1 }}</h1>
			<p>{{ question.description }}</p>

			<table class="table">
				<tr>
					<td></td>
					<td v-for="answer in [question.answers][0]" class="matrix-header text-center" :key="'mtd' + answer.id">
						{{ answer.name }}
					</td>
				</tr>
				<tr v-for="(subquestion, idx) in questions" :key="'mtq' + idx + subquestion.id">
					<td>
						<div class="matrix-label mb-2">
							{{ subquestion.name }}
						</div>
					</td>

					<td v-for="(answer, idxy) in subquestion.answers" class="text-center" v-bind:style="{ width: 50 / subquestion.answers.length + '%' }" :key="'mtad' + idxy + subquestion.id">
						<input
							:id="'answer' + idx + '_' + idxy"
							:name="'answer' + idx"
							:data-name="'answer' + idx"
							class="range matrix"
							type="radio"
							:value="answer.id"
							:checked="givenAnswer && givenAnswer[idx] == answer.id"
						/>

<!--						:checked="givenAnswer && givenAnswer.indexOf(answer.id) > -1"-->
						<label v-bind:for="'answer' + idx + '_' + idxy">
							<i class="far fa-circle"></i>
							<i class="far fa-check-circle"></i>
						</label>
					</td>
				</tr>
			</table>
		</div>

		<div class="text-center mt-3">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">{{ survey.previous }}</button>
			<button type="button" class="btn mx-2" @click="doAnswer">{{ survey.next }}</button>
		</div>
	</div>
</template>

<style scoped>
div.main > div > p {
	white-space: pre-line;
}

div >>> td {
	padding: 0;
	border: 0;
	border-right: 8px solid #0000 !important;
}
</style>

<script>
import $ from 'jquery';

export default {
	name: 'MatrixType',
	props: ['survey', 'question', 'givenAnswer', 'isfirstquestion'],
	data() {
		return {
			questions: [],
			answers: [],
		};
	},
	computed: {
		previousAnswers: function() {
			return this.$props.givenAnswer;
		}
	},
	methods: {
		doAnswer() {
			let answers = this.questions.map((q, idx) => {
				let answer = $('input[data-name="answer' + idx + '"]:checked').val();
				return answer ? answer : null;
			});
			this.$emit('answer', answers);
		},
		isChecked(index, id) {
			if (this.previousAnswers && this.previousAnswers[index] === id) {
				return true;
			} else {
				return false;
			}
		},
	},

	mounted() {
		this.questions = [this.question].concat(this.question.following);
	},
};
</script>
