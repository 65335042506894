<template>
	<div class="main">
		<h1>{{ question.name }} <span v-if="question.is_required === true" style="color: darkred;">*</span></h1>
		<p>{{ question.description }}</p>

		<div class="col-12 text-center">
			<div v-for="(answer, index) in question.answers" class="d-inline-block mx-2" :key="'s' + index + answer.id">
				<input v-bind:id="'answer' + index" name="answer" type="radio" class="smileys" v-bind:value="answer.id" v-bind:checked="givenAnswer === answer.id" v-on:click="doAnswer" />
				<label v-bind:for="'answer' + index">
					<div class="text-danger mx-3 my-1" v-if="index === 0"><img :src="baseUrl + '/media/smileys/face_with_steam_from_nose.gif'" /></div>
					<div class="text-warning mx-3 my-1" v-else-if="index === 1"><img :src="baseUrl + '/media/smileys/frowning_face.gif'" /></div>
					<div class="text-success mx-3 my-1" v-else-if="index === 2"><img :src="baseUrl + '/media/smileys/smiling_face.gif'" /></div>
					<div class="mx-3 my-1" v-else-if="index === 3" style="color: darkgreen;"><img :src="baseUrl + '/media/smileys/smiling_face_with_heart_eyes.gif'" /></div>
				</label>
			</div>
		</div>

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7 question-image-container">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center mt-3">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">{{ survey.previous }}</button>
			<button type="button" class="btn mx-2" @click="doAnswer">{{ survey.next }}</button>
		</div>
	</div>
</template>

<style scoped>
div.main > p {
	white-space: pre-line;
}
label > div > img {
	width: 6rem;
}
</style>

<script>
import $ from 'jquery';

export default {
	name: 'SmileyType',
	props: ['survey', 'question', 'givenAnswer', 'isfirstquestion'],
	data() {
		return {
			answers: [],
		};
	},
	computed: {
		baseUrl() {
			return process.env.VUE_APP_URL;
		},
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
	},
	methods: {
		doAnswer() {
			let answer = $('input[name="answer"]:checked').val();
			this.$emit('answer', answer ? answer : null);
		},
	},
};
</script>
