<template>
	<div class="chart"></div>
</template>

<style scoped>
.chart {
	position: relative;
	margin: 0;
	width: 50px;
	height: 50px;
}

.chart >>> canvas {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.chart >>> span {
	display: block;
	line-height: 50px;
	text-align: center;
	width: 50px;
	font-size: 15px;
	font-weight: 100;
}
</style>

<script>
import $ from 'jquery';

export default {
	name: 'SubscriberProgressCircle',
	props: ['progress', 'primary', 'secondary'],
	mounted() {
		let el = $('.chart'); // get canvas

		let options = {
			percent: this.$props.progress,
			size: 50,
			lineWidth: 5,
			rotate: 0,
		};

		let canvas = document.createElement('canvas');
		let span = document.createElement('span');
		span.textContent = options.percent + '%';

		let ctx = canvas.getContext('2d');
		canvas.width = canvas.height = options.size;

		el.append(span);
		el.append(canvas);

		ctx.translate(options.size / 2, options.size / 2); // change center
		ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg

		let radius = (options.size - options.lineWidth) / 2;

		let drawCircle = function(color, lineWidth, percent) {
			percent = Math.min(Math.max(0, percent || 1), 1);
			ctx.beginPath();
			ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false);
			ctx.strokeStyle = color;
			ctx.lineCap = 'round'; // butt, round or square
			ctx.lineWidth = lineWidth;
			ctx.stroke();
		};

		drawCircle(this.$props.secondary, options.lineWidth, 100 / 100);
		drawCircle(this.$props.primary, options.lineWidth, options.percent / 100);
	},
};
</script>
