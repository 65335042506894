<template>
	<div>
		<h1>{{ question.name }} <span v-if="question.is_required === true" style="color: darkred;">*</span></h1>
		<p>{{ question.description }}</p>

		<input type="number" class="form-control" v-on:keyup.enter="doAnswer" v-bind:value="givenAnswer" />

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7 question-image-container">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center mt-3">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">ZURÜCK</button>
			<button type="button" class="btn mx-2" @click="doAnswer">WEITER</button>
		</div>
	</div>
</template>

<style scoped></style>

<script>
import $ from 'jquery';

export default {
	name: 'NumericType',
	props: ['question', 'givenAnswer', 'isfirstquestion'],
	data() {
		return {
			allowsNext: false,
			answers: [],
		};
	},
	computed: {
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
	},
	// mounted() {
	// 	console.log(this.givenAnswer);
	// },
	methods: {
		doAnswer() {
			this.$emit('answer', $('input').val());
		},
	},
};
</script>
