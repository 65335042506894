<template>
	<div class="p-1 p-md-3 p-lg-5 survey-question-container">
		<RadioType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-if="question.type === 'radio'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion" :lang="lang"></RadioType>
		<InformationType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'information'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></InformationType>
		<TextType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'text'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></TextType>
		<CheckboxType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'checkbox'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion" :lang="lang"></CheckboxType>
		<NumericType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'numeric'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></NumericType>
		<RangeType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'range'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></RangeType>
		<MatrixType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'matrix'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></MatrixType>
		<NpsType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'nps'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></NpsType>
		<ContactType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'contact'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></ContactType>
		<SmileyType :survey="survey" v-bind:question="question" v-bind:givenAnswer="answer" v-else-if="question.type === 'smileys'" @answer="doAnswer" @previousQuestion="$emit('previousQuestion')" :isfirstquestion="isfirstquestion"></SmileyType>
	</div>
</template>

<style scoped>
div >>> h1 {
	font-size: 1.9rem;
}
</style>

<script>
import RadioType from '@/view/component/subscriber/Partials/QuestionTypes/RadioType';
import InformationType from '@/view/component/subscriber/Partials/QuestionTypes/InformationType';
import TextType from '@/view/component/subscriber/Partials/QuestionTypes/TextType';
import CheckboxType from '@/view/component/subscriber/Partials/QuestionTypes/CheckboxType';
import NumericType from '@/view/component/subscriber/Partials/QuestionTypes/NumericType';
import RangeType from '@/view/component/subscriber/Partials/QuestionTypes/RangeType';
import MatrixType from '@/view/component/subscriber/Partials/QuestionTypes/MatrixType';
import NpsType from '@/view/component/subscriber/Partials/QuestionTypes/NpsType';
import ContactType from '@/view/component/subscriber/Partials/QuestionTypes/ContactType';
import SmileyType from '@/view/component/subscriber/Partials/QuestionTypes/SmileysType';
import * as languageData from '@/core/config/languages.json';

export default {
	name: 'SubscriberSurveyQuestion',
	components: {
		SmileyType,
		ContactType,
		NpsType,
		MatrixType,
		RangeType,
		NumericType,
		CheckboxType,
		TextType,
		InformationType,
		RadioType,
	},
	props: ['survey', 'question', 'answer', 'lang', 'isfirstquestion'],
	data() {
		return {
			answers: {},
		};
	},
	methods: {
		doAnswer(answer) {
			if (this.question.is_required === true && (answer == null || (Array.isArray(answer) && answer.length === 0))) {
				let warningText = languageData.filter(value => { return value.code === 'en'; })[0].required;
				let langData = languageData.filter(value => {
					return value.code === this.lang;
				});

				if (langData != null && langData.length === 1 && 'required' in langData[0]) {
					warningText = langData[0].required;
				}

				this.$toast.info(warningText, {
					timeout: 2000,
				});
			} else {
				this.$emit('answer', answer);
			}
		},
	},
};
</script>
