<template>
	<div>
		<div class="row justify-content-center">
			<div class="col col-lg-8 text-center last-step p-5">
				<div v-if="error && $route.name !== 'survey-preview'" class="alert alert-danger">
					<template v-if="language === 'de'">Beim Speichern Ihrer Antworten ist leider ein technischer Fehler aufgetreten</template>
					<template v-else>Technical error while processing your answers</template>
				</div>
				<h1 v-if="loading && $route.name !== 'survey-preview'" class="text-center">
					<template v-if="language === 'de'">Ihre Antworten werden gespeichert...</template>
					<template v-else>Processing answers...</template>
				</h1>

				<div v-if="(!loading && !error) || $route.name === 'survey-preview'">
					<h1 v-if="survey.finished_header != null">{{ survey.finished_header }}</h1>
					<h1 v-else>Vielen Dank für Ihre Teilnahme.</h1>

					<p class="my-5" v-if="survey.finished_body != null">{{ survey.finished_body }}</p>
					<p class="my-5" v-else>Sie können die Befragung jetzt schließen.</p>
				</div>

				<button v-if="$route.name === 'survey-preview'" type="button" class="btn mt-5 mb-5" v-on:click="restart">Neustart</button>
			</div>
		</div>
	</div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
	name: 'SubscriberEnd',
	props: {
		survey: Object,
		language: String,
	},
	data() {
		return {
			loading: true,
			error: false,
			answers: {},
		};
	},
	methods: {
		restart: function() {
			location.reload();
		},
		restoreAnswers() {
			let savedAnswers = localStorage.getItem('answers');

			if (!savedAnswers) {
				this.answers = {};
				return;
			}

			this.answers = JSON.parse(savedAnswers);
		},

		buildQuestionArray() {
			let questions = [];
			this.survey.topics.forEach(t => {
				questions = questions.concat(t.questions);
			});

			let matrixTitle = null;
			let matrixMainQuestion = null;
			let matrixTopic = null;
			questions.forEach((q, idx) => {
				if (q.type != 'matrix') {
					matrixTitle = null;
					matrixMainQuestion = null;
					matrixTopic = null;
					return;
				}

				if (null === matrixTitle || matrixTitle !== q.option_1 || matrixTopic !== q.topic_id) {
					// first question
					matrixTitle = q.option_1;
					matrixMainQuestion = q;
					matrixTopic = q.topic_id;
					matrixMainQuestion.following = [];
				} else {
					// following questions
					matrixMainQuestion.following.push(q);
					delete questions[idx];
				}
			});

			this.questions = questions.filter(q => q);

			this.checkAnswers();
		},

		checkAnswers() {
			let addQuestion = true;
			let followQuestion = '';

			this.questions = [];
			this.survey.topics.forEach(t => {
				t.questions.forEach(q => {
					if (followQuestion === q.id || followQuestion === q.topic_id) {
						addQuestion = true;
					}
					if (addQuestion === true) {
						this.questions.push(q);
					}

					if (Object.keys(this.answers).includes(q.id)) {
						let answerVal = this.answers[q.id];

						if (!Array.isArray(answerVal)) {
							let answerIndex = q.answers.findIndex(answered => answered.id == answerVal);

							if (answerIndex !== -1) {
								let answer = q.answers[answerIndex];

								if (answer.follow_question_id != null) {
									followQuestion = answer.follow_question_id;
									addQuestion = false;
								} else if (answer.follow_topic_id != null) {
									followQuestion = answer.follow_topic_id;
									addQuestion = false;
								}
							}
						}
					}
				});
			});

			this.buildMatrix();
		},

		buildMatrix() {
			let matrixTitle = null;
			let matrixMainQuestion = null;
			let matrixTopic = null;
			this.questions.forEach((q, idx) => {
				if (q.type != 'matrix') {
					matrixTitle = null;
					matrixMainQuestion = null;
					matrixTopic = null;
					return;
				}

				if (null === matrixTitle || matrixTitle !== q.option_1 || matrixTopic !== q.topic_id) {
					// first question
					matrixTitle = q.option_1;
					matrixMainQuestion = q;
					matrixTopic = q.topic_id;
					matrixMainQuestion.following = [];
				} else {
					// following questions
					matrixMainQuestion.following.push(q);
					delete this.questions[idx];
					// this.questions.splice(idx, 1);
				}
			});

			this.questions = this.questions.filter(q => q);
		},

		sendAnswersToServer() {
			let data = [];
			for (const [key, question] of Object.entries(this.questions)) {
				key;
				if (question.type === 'contact') {
					data.push({
						question_id: question.id,
						value: this.answers[question.id],
					});
				} else {
					if (Array.isArray(this.answers[question.id])) {
						this.answers[question.id].forEach(ans => {
							if (ans !== '' && ans != null) {
								data.push({
									question_id: question.id,
									value: ans,
								});
							}
						});
					} else {
						if (this.answers[question.id] !== '' && this.answers[question.id] != null) {
							data.push({
								question_id: question.id,
								value: this.answers[question.id],
							});
						}
					}
				}
			}

			let uri = this.$route.params.uri;

			if (typeof this.$route.params.customer != 'undefined') {
				uri += '/' + this.$route.params.customer;
			}

			let postData = {
				answers: data,
				visitor: window.visitorId,
				language: this.language
			};

			ApiService.post('subscriber/' + uri, postData)
				.then(() => {
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
					this.error = true;
				});
		},
	},

	mounted() {
		this.restoreAnswers();
		this.buildQuestionArray();

		// if (this.questions.length > Object.values(this.answers).length) {
		//     this.$router.push({path: '/'});
		// }

		if (this.$route.name !== "survey-preview") {
			this.sendAnswersToServer();
		}
	},
};
</script>
