<template>
	<div class="main">
		<h1>{{ question.name }}</h1>
		<p>{{ question.description }}</p>

		<div class="row">
			<div class="col-12 col-md-6" v-for="(field, fi) in question.option_2" :key="'field-' + fi">
				<div class="form-group" v-if="field.type !== 'datetime' && field.type !== 'time' && field.type !== 'date'">
					<label style="font-size: 1.2rem;">{{ field.name }}</label>
					<input type="text" v-model="field.value" class="form-control" :class="isValid(field) ? '' : 'is-invalid'" />
				</div>
				<div class="form-group" v-else-if="field.type === 'datetime'">
					<label style="font-size: 1.2rem;">{{ field.name }}</label>
					<input type="text" v-model="field.value" class="form-control datetime-input" :class="isValid(field) ? '' : 'is-invalid'" />
				</div>
				<div class="form-group" v-else-if="field.type === 'date'">
					<label style="font-size: 1.2rem;">{{ field.name }}</label>
					<input type="text" v-model="field.value" class="form-control date-input" :class="isValid(field) ? '' : 'is-invalid'" />
				</div>
				<div class="form-group" v-else-if="field.type === 'time'">
					<label style="font-size: 1.2rem;">{{ field.name }}</label>
					<input type="text" v-model="field.value" class="form-control time-input" :class="isValid(field) ? '' : 'is-invalid'" />
				</div>
			</div>
		</div>

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7 question-image-container">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center mt-3">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">{{ survey.previous }}</button>
			<button type="button" class="btn mx-2" @click="doAnswer">{{ survey.next }}</button>
		</div>
	</div>
</template>

<style scoped>
div.main > p {
	white-space: pre-line;
}
@import "~flatpickr/dist/flatpickr.min.css";

input[type='text'].is-invalid {
	border-bottom: 3px solid #d32f2f;
}
</style>

<script>
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

export default {
	name: 'ContactType',
	props: ['survey', 'question', 'givenAnswer', 'isfirstquestion'],
	data() {
		return {
			allowsNext: false,
			clickedNext: false,
		};
	},
	computed: {
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
	},
	mounted() {
		flatpickr(".datetime-input", {
			enableTime: true,
			dateFormat: "d.m.Y H:i",
			minDate: new Date().addDays(1),
			time_24hr: true,
			"locale": German
		});
		flatpickr(".date-input", {
			dateFormat: "d.m.Y",
			minDate: new Date().addDays(1),
			"locale": German
		});
		flatpickr(".time-input", {
			enableTime: true,
			noCalendar: true,
			dateFormat: "H:i",
			minDate: new Date().addDays(1),
			time_24hr: true,
			"locale": German
		});
	},
	methods: {
		doAnswer() {
			if (this.validate() === true) {
				let answer = this.question.option_2;
				this.$emit('answer', answer);
			} else {
				this.clickedNext = true;
			}
		},
		validate() {
			let returnVal = true;
			if (this.question.option_2 != null) {
				this.question.option_2.forEach(field => {
					if (!this.isValid(field)) {
						returnVal = false;
					}
				});

				return returnVal;
			}
		},
		isValid: function(field) {
			if (field.value != null) {
				if (field.type === 'mail') {
					return this.validateMail(field.value);
				} else if (field.type === 'number') {
					return !isNaN(field.value);
				}

				if (field.required === true) {
					if (field.value.length === 0) {
						return false;
					} else {
						if (field.type === 'text') {
							return true;
						}
					}
				}
			} else {
				if (field.required === true) {
					return false;
				}
			}

			return true;
		},
		validateMail: function(email) {
			// eslint-disable-next-line no-useless-escape
			const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
	},
};
</script>
