<template>
	<div>
		<div class="background" v-bind:style="style.background"></div>
		<div class="surveyCont" style="overflow-y: auto; position: absolute; top: 0px; left: 0px; width: 100vw">
			<div v-if="error === 'error'" class="alert alert-danger">Beim Laden der Umfrage ist ein Fehler aufgetreten.</div>
			<div v-if="error === 'notfound'" style="height: 100vh;">
				<div class="container-fluid">
					<div class="row pt-3">
						<div class="col-12 col-md-3 justify-content-end">

						</div>
						<div class="d-none d-md-flex col-md-6"></div>
						<div class="col-12 col-md-3 justify-content-end">
							<div class="p-0 px-3 pt-3 pt-md-3 pr-md-3 pb-md-0 pl-md-0">
								<div style="min-height: 7rem"></div>
							</div>
						</div>
					</div>

					<div class="row justify-content-center">
						<div class="col col-lg-8 text-center first-step p-5">
							<h1>Die Umfrage konnte nicht gefunden werden.</h1>
						</div>
					</div>
				</div>
			</div>

			<h1 v-if="loading" class="text-center">Wird geladen...</h1>
			<div v-if="survey">
				<div class="container-fluid">
					<div class="row pt-3">
						<div class="col-12 col-md-3 justify-content-end">
							<div class="topbar-item text-right" v-if="error === ''">
								<b-dropdown size="sm" variant="link" toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none" no-caret right no-flip>
									<template v-slot:button-content>
										<img class="d-none d-md-block rounded-sm" :src="baseUrl + '/media/svg/flags/' + activeLanguage + '.svg'" alt="" height="32" width="32" />
										<img class="d-block d-md-none h-20px w-20px rounded-sm" :src="baseUrl + '/media/svg/flags/' + activeLanguage + '.svg'" alt="" />
									</template>
									<b-dropdown-text tag="div" class="min-w-md-175px">
										<ul class="navi navi-hover py-4">
											<template v-for="(item, i) in survey.translations.languages">
												<li class="navi-item" :class="{ 'navi-item-active': isActiveLanguage(item) }" :key="i">
													<a href="" class="navi-link" v-bind:data-lang="item" v-on:click.prevent="selectedLanguage">
														<span class="symbol symbol-20 mr-3">
															<img :src="baseUrl + '/media/svg/flags/' + item + '.svg'" alt="" />
														</span>
														<span class="navi-text">{{ getNativeName(item) }}</span>
													</a>
												</li>
											</template>
										</ul>
									</b-dropdown-text>
								</b-dropdown>
							</div>
						</div>
						<div class="d-none d-md-flex col-md-6"></div>
						<div class="col-12 col-md-3 justify-content-end">
							<div class="p-0 px-3 pt-3 pt-md-3 pr-md-3 pb-md-0 pl-md-0">
								<img v-if="survey.theme.logo !== '' && survey.theme.logo != null" v-bind:src="storageUrl + '/surveyAppearance/' + survey.theme.logo" class="img-fluid logo-img" />
								<div v-else style="min-height: 7rem"></div>
							</div>
						</div>
					</div>

					<SubscriberStart v-if="activeQuestion === 'start'" :survey="survey" :error="error" @nextQuestion="setQuestion"></SubscriberStart>
					<SubscriberEnd v-else-if="activeQuestion === 'end' && error === ''" :survey="survey" :language="activeLanguage"></SubscriberEnd>
					<SubscriberQuestions v-else-if="error === ''" :survey="survey" :question-id="activeQuestion"
					                     @nextQuestion="setQuestion" :lang="activeLanguage"
					                     @addQuestionTracker="addQuestionTracking" @removeQuestionTracker="removeQuestionTracking"
					                     :question-tracker="questionTracker" ref="subscriberQuestion">

					</SubscriberQuestions>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="d-flex align-items-center justify-content-between">
				<div class="text-dark">
					<span class="text-white">
						powered by PrinorQuest 2021 &nbsp;&copy;&nbsp;
					</span>
				</div>
        <div class="">
          <a href="https://team-psychology.com/datenschutz/" target="_blank" class="text-white pr-3 pl-0">
            Datenschutz
          </a>
          <a href="https://team-psychology.com/impressum/" target="_blank" class="text-white px-3">
            Impressum
          </a>
        </div>
			</div>
		</div>
	</div>
</template>

<style scoped>
div.footer {
	pointer-events: none;
}
div.footer a {
	pointer-events: all;
}
.logo-img {
	max-height: 20vh;
}

.background {
	height: 100vh !important;
	width: 100vw;
}

.background:before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	opacity: var(--opacity);

	background-image: var(--image);

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.footer {
	position: fixed;
	pointer-events: none;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: right;
	background-color: #000;
	color: #fff;
	padding: 0 15px;
}
</style>

<script>
import ApiService from '@/core/services/api.service';
import SubscriberStart from '@/view/component/subscriber/Steps/FirstStep';
import SubscriberEnd from '@/view/component/subscriber/Steps/LastStep';
import SubscriberQuestions from '@/view/component/subscriber/Steps/Survey';
import * as languageData from '@/core/config/languages.json';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import TranslationAnswer from "@/data/survey/translation/Answer";

export default {
	name: 'SubscriberSurvey',
	components: { SubscriberQuestions, SubscriberEnd, SubscriberStart },
	data() {
		return {
			style: {
				background: null,
			},
			survey: null,
			loading: true,
			finished: false,
			error: '',
			activeQuestion: 'start',
			activeLanguage: null,
			languages: [],
			questionTracker: [],
		};
	},
	computed: {
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		baseUrl() {
			return process.env.VUE_APP_URL;
		},
	},
	mounted() {
		this.languages = languageData.default;

		FingerprintJS.load().then(fp => {
			fp.get().then(result => {
				let uri = this.$route.params.uri + '/' + result.visitorId;

				if (typeof this.$route.params.customer != 'undefined') {
					uri += '/' + this.$route.params.customer;
				}

				if (this.$route.name === "survey-preview") {
					uri = this.$route.params.id + '/' + window.visitorId + '/preview';
				}

				ApiService.get('subscriber/' + uri)
					.then(response => {
						if (response.status === 200) {
							this.survey = response.data.data;
							this.activeLanguage = this.survey.language;

							this.style.background = {
								position: 'absolute',
								top: '0px',
								left: '0px',
								'background-color': this.survey.theme.background_color,
							};

							if (this.survey.theme.background_picture) {
								this.style.background['--image'] = 'url(' + this.storageUrl + '/surveyAppearance/' + this.survey.theme.background_picture + ')';
								// this.style.background['--image'] = 'url(/' + this.survey.theme.backgroundPicture + ')';
							}
							if (this.survey.theme.background_picture_opacity) {
								this.style.background['--opacity'] = this.survey.theme.background_picture_opacity;
							}

							this.survey.topics.forEach(topic => {
								topic.questions.forEach(question => {
									if (question.type === 'contact') {
										if (question.option_2 !== null) {
											question.option_2.forEach(field => {
												let f = new TranslationAnswer();
												f.code = this.survey.language;
												f.name = field.name;

												if ('translation' in field) {
													field.translation.push(f);
												}
											});
										}
									}
								});
							});

							this.createCssClasses(this.survey.theme);

							this.loading = false;

							if (this.survey.message === 'participated') {
								this.$toast.info('Sie haben bereits an dieser Umfrage teilgenommen. Bei erneuter Teilnahme werden alle Antworten aus der vorherigen Teilnahme unwiderruflich gelöscht und überschrieben!', {
									timeout: 0,
								});
							}
						}
						else if (response.status === 208) {
							this.survey = response.data.data;
							this.style.background = {
								position: 'absolute',
								top: '0px',
								left: '0px',
								'background-color': this.survey.theme.background_color,
							};

							if (this.survey.theme.background_picture) {
								this.style.background['--image'] = 'url(' + this.storageUrl + '/surveyAppearance/' + this.survey.theme.background_picture + ')';
								// this.style.background['--image'] = 'url(/' + this.survey.theme.backgroundPicture + ')';
							}
							if (this.survey.theme.background_picture_opacity) {
								this.style.background['--opacity'] = this.survey.theme.background_picture_opacity;
							}

							this.createCssClasses(this.survey.theme);

							this.loading = false;
							this.error = 'reported';
						}
						else if (response.status === 204) {
							this.style.background = {
								position: 'absolute',
								top: '0px',
								left: '0px',
								'background-color': '#000',
							};

							this.style.background['--image'] = 'url(https://app.prinorquest.com/media/error/bg6.jpg)';

							this.loading = false;
							this.error = 'notfound';
						}
					})
					.catch(() => {
						this.loading = false;
						this.error = 'error';
					});
			});
		});
	},
	methods: {
		addQuestionTracking: function(q) {
			this.questionTracker.push(q);
		},
		removeQuestionTracking: function() {
			this.questionTracker.pop();
		},
		selectedLanguage: function(e) {
			const el = e.target.closest('.navi-link');
			const lang = el.getAttribute('data-lang');
			this.activeLanguage = lang;
			this.updateLanguageText();
		},
		updateLanguageText: function() {
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'name');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'title');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'sub_title');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'description');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'start');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'next');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'previous');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'others');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'no_answer');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'finished_header');
			this.languageText(this.survey, this.survey.translations.survey[this.survey.language], this.survey.translations.survey[this.activeLanguage], 'finished_body');

			this.survey.topics.forEach(topic => {
				if (this.activeLanguage in this.survey.translations.topics && topic.id in this.survey.translations.topics[this.activeLanguage]) {
					this.languageText(topic, this.survey.translations.topics[this.survey.language][topic.id], this.survey.translations.topics[this.activeLanguage][topic.id], 'name');
					this.languageText(topic, this.survey.translations.topics[this.survey.language][topic.id], this.survey.translations.topics[this.activeLanguage][topic.id], 'title');
					this.languageText(topic, this.survey.translations.topics[this.survey.language][topic.id], this.survey.translations.topics[this.activeLanguage][topic.id], 'description');
				}

				topic.questions.forEach(question => {
					if (question) {
						if (this.activeLanguage in this.survey.translations.topics && topic.id in this.survey.translations.topics[this.activeLanguage]) {
							this.languageText(question, this.survey.translations.topics[this.survey.language][topic.id]['questions'][question.id], this.survey.translations.topics[this.activeLanguage][topic.id]['questions'][question.id], 'name');
							this.languageText(question, this.survey.translations.topics[this.survey.language][topic.id]['questions'][question.id], this.survey.translations.topics[this.activeLanguage][topic.id]['questions'][question.id], 'description');

							if (question.type === 'matrix') {
								this.languageText(question, this.survey.translations.topics[this.survey.language][topic.id]['questions'][question.id], this.survey.translations.topics[this.activeLanguage][topic.id]['questions'][question.id], 'option_0');
								this.languageText(question, this.survey.translations.topics[this.survey.language][topic.id]['questions'][question.id], this.survey.translations.topics[this.activeLanguage][topic.id]['questions'][question.id], 'option_1');
							}

							question.answers.forEach(answer => {
								if (question.id in this.survey.translations.topics[this.activeLanguage][topic.id]['questions']) {
									this.languageText(answer, this.survey.translations.topics[this.survey.language][topic.id]['questions'][question.id]['answers'][answer.id], this.survey.translations.topics[this.activeLanguage][topic.id]['questions'][question.id]['answers'][answer.id], 'name');
								}
							});
						}

						if (question.type === 'contact' && question.option_2 != null) {
							question.option_2.forEach(field => {
								if (field.translation != null) {
									field.translation.forEach(trans => {
										if (trans.code === this.activeLanguage) {
											field.name = trans.name;
										}
									});
								}
							});
						}
					}
				});
			});
		},
		languageText: function(obj, original, translation, key, keyTranslation = '') {
			if (keyTranslation === '') {
				keyTranslation = key;
			}

			if (translation) {
				if (key in translation && translation[key] != null) {
					obj[key] = translation[keyTranslation];
				} else {
					obj[key] = original[keyTranslation];
				}
			}
		},
		isActiveLanguage: function(lang) {
			return this.activeLanguage === lang;
		},
		getNativeName: function(lang) {
			let filtered = this.languages.filter(value => {
				return value.code === lang;
			});
			if (filtered.length === 1) {
				return filtered[0].nativeName;
			} else {
				return lang;
			}
		},
		setQuestion: function(id) {
			let that = this;

			if (this.activeQuestion === 'start') {
				let firstQuestion = this.survey.topics[0].questions[0];
				this.addQuestionTracking(firstQuestion);
				this.activeQuestion = firstQuestion.id;
			}
			else {
				this.$refs.subscriberQuestion.changeQuestion(() => {
					that.activeQuestion = id;
				});
			}
		},
		createCssClasses(theme) {
			var style = document.createElement('style');
			style.innerHTML = `
            body, #survey-app {
                height: 100vh;
            }

            .question-image-container > p {
                margin-top: 1rem;
				font-size: 1.2rem;
                word-wrap: break-word;
                white-space: break-spaces;
            }

            .footer span {
                font-size: 0.75rem;
            }

            .first-step, .last-step {
                margin-top: 20vh;
                margin-bottom: 20vh;
            }

            div > button.btn:first-of-type {
                margin-right: 1rem;
            }

            .surveyCont {
                height: 100vh !important;
            }

            @media (max-width: 500px) {
                .first-step, .last-step {
                    margin-top: 0vh;
                }
                .survey-wrapper .footer {
                    padding-top: 2px;
                }
                .surveyCont {
                    height: calc(100vh - 95px) !important;
                }
            }

            button, .btn {
                border-radius: 100px;
                background-color: #0000;
                color: ${theme.text_body_color};
                border: 1px solid ${theme.button_color};
                outline: none;
                overflow: hidden;
            }
            button:disabled, .btn:disabled {
                background-color: #ccc;
                color: #000;
            }

            .btn {
                display: inline-block;
                vertical-align: middle;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                position: relative;
                -webkit-transition-property: color;
                transition-property: color;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
            }
            .btn:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: ${theme.button_color};
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.5s;
                transition-duration: 0.5s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
            .btn:hover, .btn:focus, .btn:active {
                color: ${theme.button_text_color};
            }
            .btn:hover:before, .btn:focus:before, .btn:active:before {
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }

            .question, .last-step {
                /*background-color: ${theme.body_color};*/
                color: ${theme.text_body_color};
            }

            .first-step {
                color: ${theme.text_header_color};
            }

            .survey {
                color: ${theme.text_body_color};
                user-select: none;
                margin-bottom: 20vh;
            }

            .survey h1, .survey h2, .survey h3, .survey h4, .survey h5, .survey h6, .survey .bottom-text {
                color: ${theme.text_header_color};
                word-wrap: break-word;
                white-space: break-spaces;
            }

            div.form-group label {
                color: ${theme.text_header_color};
            }

            .chart > span {
                color: ${theme.text_header_color};
            }

            .survey td.matrix-header {
                color: ${theme.text_header_color};
                padding: 5px;
                font-size: 1.2rem;
            }

            .survey hr {
                border-top: 1px solid ${theme.text_header_color};
            }

            .survey-wrapper .next:disabled, .survey-wrapper .previous:disabled {
                display: none
            }

            .survey-wrapper .next, .survey-wrapper .previous {
                cursor: pointer;
                opacity: 0.8;
                width: 50px;
                height: 50px;
                padding: 0;
                margin: 0;
                border-radius: 50%;
                border: solid 2px #888589;
                background-color: #0000;
            }
            .survey-wrapper .next:hover, .survey-wrapper .previous:hover {
                opacity: 1;
            }
            @media (max-width: 500px) {
                .survey-wrapper .next, .survey-wrapper .previous {
                    color: #000;
                }
            }

            .survey .form-control {
                border-radius: 0;
                background-color: ${theme.element_color};
                color: ${theme.element_text_color};
                border: none;
                border-bottom: 3px solid ${theme.element_active_color};
            }

            .survey .form-control:focus {
                border-color: ${theme.element_active_color};
                background-color: ${theme.element_active_color};
                color: ${theme.element_text_active_color};
            }

            .survey input[type="checkbox"], .survey input[type="radio"] {
                display:none;
            }

            .survey input[type="checkbox"] + label:not(.editable),
            .survey input[type="checkbox"] + label.editable,
            .survey input[type="radio"]:not(.range):not(.smileys) + label:not(.editable),
            .survey input[type="radio"]:not(.range):not(.smileys) + label.editable {
                cursor: pointer;
                padding: 0 7px;
                width: calc(100% - 43px);
                position: relative;
                margin-left: 43px;
                line-height: 35px;
                background-color: ${theme.element_color};
                font-size: 1.3rem;
            }

            .survey input[type="checkbox"] + label.editable,
            .survey input[type="radio"]:not(.range):not(.smileys) + label.editable {
                border-bottom: 3px solid ${theme.element_active_color};
            }

            .survey input[type="checkbox"] + label:not(.editable)::before,
            .survey input[type="checkbox"] + label.editable::before,
            .survey input[type="radio"]:not(.range):not(.smileys) + label:not(.editable)::before,
            .survey input[type="radio"]:not(.range):not(.smileys) + label.editable::before {
                cursor: pointer;
                font-family: "Font Awesome 5 Free";
                width: 35px;
                height: 35px;
                background-color: ${theme.element_color};
                display: block;
                content: "\\f0c8";
                text-align: center;
                color: ${theme.element_text_color};
                position: absolute;
                left: -43px;
                top: 0;
            }
            .survey input[type="radio"]:not(.range):not(.smileys) + label:not(.editable)::before,
            .survey input[type="radio"]:not(.range):not(.smileys) + label.editable::before {
                content: "\\f111";
            }

            .survey input[type="radio"].smileys + label {
                background-color: ${theme.element_color};
                font-size: 1.3rem;
            }

            .survey input[type="checkbox"]:checked + label:not(.editable),
            .survey input[type="radio"]:checked + label:not(.editable) {
                color: ${theme.element_text_active_color};
                background-color: ${theme.element_active_color} !important;
                font-size: 1.3rem;
            }
            .survey input[type="checkbox"]:checked + label:not(.editable)::before,
            .survey input[type="checkbox"]:checked + label.editable::before {
                content: "\\f14a";
                color: ${theme.element_text_active_color};
                background-color: ${theme.element_active_color} !important;
            }

            .survey input[type="radio"]:not(.range):not(.smileys):checked + label:not(.editable)::before,
            .survey input[type="radio"]:not(.range):not(.smileys):checked + label.editable::before {
                content: "\\f058";
                color: ${theme.element_text_active_color};
                background-color: ${theme.element_active_color} !important;
            }

            .survey input[type="radio"].range + label, .survey .matrix-label {
                cursor: pointer;
                display: block;
                line-height: 35px;
                color: ${theme.element_text_color};
                background-color: ${theme.element_color};
                padding-left: 5px;
                font-size: 1.3rem;
            }
            .survey input[type="radio"].range:checked + label {
                cursor: pointer;
                color: ${theme.element_text_active_color};
                background-color: ${theme.element_active_color};
                font-size: 1.3rem;
            }

            .survey input[type="radio"].matrix + label,
             .survey input[type="radio"].matrix + label i{
                cursor: pointer;
                font-family: "Font Awesome 5 Free";
                content: "\\f111";
                color: ${theme.element_text_color};
            }
            .survey input[type="radio"].matrix:checked + label,
             .survey input[type="radio"].matrix:checked + label i{
                content: "\\f058";
                color: ${theme.element_text_active_color};
            }

            .survey td {
                border: none !important;
            }
            .survey input[type="radio"].matrix + label td .fa-circle {
                line-height: 35px;
                display: block;
            }
            .survey input[type="radio"].matrix:checked + label .fa-circle {
                display: none;
            }
            .survey input[type="radio"].matrix + label .fa-check-circle {
                line-height: 35px;
                display: none;
            }
            .survey input[type="radio"].matrix:checked + label .fa-check-circle {
                display: block;
            }
            .flatpickr-day.selected, .flatpickr-day.startRange,
            .flatpickr-day.endRange, .flatpickr-day.selected.inRange,
            .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange,
            .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus,
            .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover,
            .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover,
            .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay,
            .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay,
            .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
                background: ${theme.element_active_color};
				-webkit-box-shadow: none;
				box-shadow: none;
				color: ${theme.element_text_active_color};
				border-color: ${theme.element_active_color};
            }
            `;
			document.getElementsByTagName('head')[0].appendChild(style);
		},
	},
};
</script>
