<template>
	<div class="survey-wrapper">
		<div class="survey mt-5">
			<div v-for="topic in survey.topics" class="effect" :key="'t' + topic.id">
				<div v-for="question in topic.questions" :key="'q' + question.id">
					<div v-if="id === question.id">
						<div class="row justify-content-center mb-1">
							<div class="col col-lg-8 align-self-center">
								<div class="px-1 px-md-3 px-lg-5">
									<h2 class="text-center text-md-left">{{ topic.title }}</h2>
									<h5>{{ topic.description }}</h5>
								</div>
							</div>
						</div>

						<div class="row justify-content-center">
							<div class="col col-lg-8 align-self-center question">
								<SubscriberSurveyQuestion :survey="survey" v-bind:question="question" v-bind:answer="answers[question.id]" @answer="answer" :lang="lang" @previousQuestion="previousQuestion" :isfirstquestion="isFirstQuestion()"></SubscriberSurveyQuestion>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="footer container-fluid">
			<div class="row">
				<div class="col">
					<SubscriberProgressCircle :key="counter" v-bind:progress="progress()" :primary="survey.theme.text_header_color" :secondary="survey.theme.element_color"></SubscriberProgressCircle>
				</div>
				<div class="col text-right">
					<button class="next" v-bind:disabled="isFirstQuestion()" @click="previousQuestion"><i class="fa fa-chevron-up"></i></button>
					<button class="previous" v-bind:disabled="isForwardButtonDisabled()" @click="nextQuestion"><i class="fa fa-chevron-down"></i></button>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.footer {
	pointer-events: none;
}
.footer > .row > .col > button {
	pointer-events: all;
}
.effect {
	display: none;
}

.footer {
	position: fixed;
	bottom: 40px;
	left: 0;
	width: 100%;
	height: 55px;
}

h2 {
	font-size: 3rem;
}
h5 {
	white-space: pre-line;
}
</style>

<script>
import $ from 'jquery';
import SubscriberSurveyQuestion from '@/view/component/subscriber/Partials/SurveyQuestion';
import SubscriberProgressCircle from '@/view/component/subscriber/Partials/ProgressCircle';

export default {
	name: 'SubscriberQuestions',
	components: {
		SubscriberProgressCircle,
		SubscriberSurveyQuestion,
	},
	props: {
		survey: Object,
		questionId: String,
		lang: String,
		questionTracker: Array,
	},
	data() {
		return {
			counter: 0,
			maxCounter: 0,
			id: null,
			questions: [],
			answers: {},
			questionAmount: -1,
		};
	},
	methods: {
		progress() {
			let length = this.questions.length;
			let answeredCount = this.counter;

			if (0 === answeredCount) {
				return 0;
			}

			return Number((100 / length) * answeredCount).toFixed(0);
		},

		isAllowedQuestion() {
			// already answered
			if (this.id in this.answers) {
				return true;
			}

			// first unanswered question
			let firstUnansweredQuestionIdx = this.questions.reverse().findIndex(q => q.id in this.answers) - 1;

			if (firstUnansweredQuestionIdx === -2) {
				firstUnansweredQuestionIdx = 0;
			} else {
				firstUnansweredQuestionIdx = firstUnansweredQuestionIdx * -1 + this.questions.length - 1;
			}

			let firstUnansweredQuestion = this.questions.reverse()[firstUnansweredQuestionIdx];

			if (firstUnansweredQuestion && firstUnansweredQuestion.id == this.id) {
				return true;
			}

			return false;
		},

		isFirstQuestion() {
			if (undefined === this.questions[0]) {
				return false;
			}
			return this.id == this.questions[0].id;
		},
		isForwardButtonDisabled() {
			return !(this.id in this.answers);
		},

		saveAnswers() {
			localStorage.setItem('answers', JSON.stringify(this.answers));
		},
		restoreAnswers() {
			let savedAnswers = localStorage.getItem('answers');

			if (!savedAnswers) {
				this.answers = {};
				return;
			}

			this.answers = JSON.parse(savedAnswers);
			this.checkAnswers();
		},

		previousQuestion() {
			--this.counter;
			this.$emit('removeQuestionTracker');
			let id = this.questionTracker[this.questionTracker.length - 1].id;

			this.buildQuestionArray();
			this.checkAnswers();
			this.$emit('nextQuestion', id);
		},
		nextQuestion(answer) {
			let end = false;

			if (1 + this.counter === this.questions.length) {
				this.$emit('nextQuestion', 'end');
				return;
			}

			if (typeof answer == 'undefined' || answer === '') {
				++this.counter;
			} else {
				if (Array.isArray(answer)) {
					++this.counter;
				} else {
					let increase = true;
					let startIndex = this.counter + 1;

					if (startIndex > 0 && this.questions[startIndex - 1].type === 'nps') {
						let npsQuestion = this.questions[startIndex - 1];

						if (npsQuestion.option_0 != null) {
							if (npsQuestion.option_0.indexOf(':') !== -1) {
								if (npsQuestion.option_0.indexOf(';') !== -1) {
									let fromToArr = npsQuestion.option_0.split(';');
									let followArr = npsQuestion.option_1.split(';');

									for (let i = 0; i < fromToArr.length; i++) {
										let from = parseInt(fromToArr[i].split(':')[0]);
										let to = parseInt(fromToArr[i].split(':')[1]);

										if (parseInt(answer) >= from && parseInt(answer) <= to) {
											if (followArr[i] != null && followArr[i].indexOf('#ENDOFSURVEY#') === -1) {
												let qId = null;
												let tId = null;

												increase = false;
												do {
													++this.counter;
													if (followArr[i] === this.questions[this.counter].id) {
														qId = followArr[i];
													} else if (followArr[i] === this.questions[this.counter].topic_id) {
														tId = followArr[i];
													}
												} while (followArr[i] !== this.questions[this.counter].id && followArr[i] !== this.questions[this.counter].topic_id);

												this.questions.splice(startIndex, this.counter - startIndex);
												if (qId !== null) {
													this.counter = this.questions.findIndex(qu => qu.id == qId);
												} else if (tId !== null) {
													this.counter = this.questions.findIndex(qu => qu.topic_id == tId);
												}
											} else if (followArr[i] != null && followArr[i].indexOf('#ENDOFSURVEY#') > -1) {
												let deleted = this.questions.splice(startIndex, this.questions.length - startIndex);
												deleted.forEach(del => {
													delete this.answers[del.id];
												});
												this.$emit('nextQuestion', 'end');
												return;
											}
										}
									}
								} else {
									let from = npsQuestion.option_0.split(':')[0];
									let to = npsQuestion.option_0.split(':')[1];
									let follow = npsQuestion.option_1;

									if (parseInt(answer) >= from && parseInt(answer) <= to) {
										if (follow != null && follow.indexOf('#ENDOFSURVEY#') === -1) {
											let qId = null;
											let tId = null;

											increase = false;
											do {
												++this.counter;
												if (follow === this.questions[this.counter].id) {
													qId = follow;
												} else if (follow === this.questions[this.counter].topic_id) {
													tId = follow;
												}
											} while (follow !== this.questions[this.counter].id && follow !== this.questions[this.counter].topic_id);

											this.questions.splice(startIndex, this.counter - startIndex);
											if (qId !== null) {
												this.counter = this.questions.findIndex(qu => qu.id == qId);
											} else if (tId !== null) {
												this.counter = this.questions.findIndex(qu => qu.topic_id == tId);
											}
										} else if (follow != null && follow.indexOf('#ENDOFSURVEY#') > -1) {
											let deleted = this.questions.splice(startIndex, this.questions.length - startIndex);
											deleted.forEach(del => {
												delete this.answers[del.id];
											});
											this.$emit('nextQuestion', 'end');
											return;
										}
									}
								}
							}
						}
					} else if (startIndex > 0 && (this.questions[startIndex - 1].type === 'text' || this.questions[startIndex - 1].type === 'information')) {
						let follow = this.questions[startIndex - 1].option_0;

						if (follow != null && follow.indexOf('#ENDOFSURVEY#') === -1) {
							let qId = null;
							let tId = null;

							increase = false;
							do {
								++this.counter;
								if (follow === this.questions[this.counter].id) {
									qId = follow;
								} else if (follow === this.questions[this.counter].topic_id) {
									tId = follow;
								}
							} while (follow !== this.questions[this.counter].id && follow !== this.questions[this.counter].topic_id);

							this.questions.splice(startIndex, this.counter - startIndex);
							if (qId !== null) {
								this.counter = this.questions.findIndex(qu => qu.id == qId);
							} else if (tId !== null) {
								this.counter = this.questions.findIndex(qu => qu.topic_id == tId);
							}
						} else if (follow != null && follow.indexOf('#ENDOFSURVEY#') > -1) {
							let deleted = this.questions.splice(startIndex, this.questions.length - startIndex);
							deleted.forEach(del => {
								delete this.answers[del.id];
							});
							this.$emit('nextQuestion', 'end');
							return;
						}
					} else {
						if (answer == null) {
							let follow = this.questions[startIndex - 1];
							let endArray = follow.answers.map(function(item) { return item.end_survey });
							let followArray = follow.answers.map(function(item) { return item.follow_question_id });

							if (endArray.every((val, i, arr) => val === arr[0]) && endArray[0] === true) {
								end = true;
								this.$emit('nextQuestion', 'end');
							}
							else if (followArray.every((val, i, arr) => val === arr[0]) && followArray[0] != null) {
								increase = false;
								do {
									++this.counter;
								} while (followArray[0] !== this.questions[this.counter].id);

								this.questions.splice(startIndex, this.counter - startIndex);
								if (followArray[0] !== null) {
									this.counter = this.questions.findIndex(qu => qu.id == followArray[0]);
								}
							}
						}
						else {
							this.questions.forEach(q => {
								q.answers.forEach(a => {
									if (a.id === answer) {
										if (a.end_survey === true) {
											let deleted = this.questions.splice(startIndex, this.questions.length - startIndex);
											deleted.forEach(del => {
												delete this.answers[del.id];
											});
											end = true;
											this.$emit('nextQuestion', 'end');
										} else {
											if (a.follow_question_id !== null || a.follow_topic_id !== null) {
												increase = false;
												do {
													++this.counter;
												} while (a.follow_question_id !== this.questions[this.counter].id && a.follow_topic_id !== this.questions[this.counter].topic_id);

												this.questions.splice(startIndex, this.counter - startIndex);
												if (a.follow_question_id !== null) {
													this.counter = this.questions.findIndex(qu => qu.id == a.follow_question_id);
												} else {
													this.counter = this.questions.findIndex(qu => qu.topic_id == a.follow_topic_id);
												}
											}
										}
									}
								});
							});
						}
					}

					if (increase === true) {
						++this.counter;
					}
				}
			}

			if (end === false) {
				this.$emit('addQuestionTracker', this.questions[this.counter]);
				this.$emit('nextQuestion', this.questions[this.counter].id);
			}
		},

		answer(answer) {
			this.answers[this.id] = answer;
			this.saveAnswers();

			this.nextQuestion(answer);
		},

		buildQuestionArray() {
			let questions = [];
			this.survey.topics.forEach(t => {
				questions = questions.concat(t.questions);
			});

			let matrixTitle = null;
			let matrixMainQuestion = null;
			let matrixTopic = null;
			questions.forEach((q, idx) => {
				if (q.type != 'matrix') {
					matrixTitle = null;
					matrixMainQuestion = null;
					matrixTopic = null;
					return;
				}

				if (null === matrixTitle || matrixTitle !== q.option_1 || matrixTopic !== q.topic_id) {
					// first question
					matrixTitle = q.option_1;
					matrixMainQuestion = q;
					matrixTopic = q.topic_id;
					matrixMainQuestion.following = [];
				} else {
					// following questions
					matrixMainQuestion.following.push(q);
					delete questions[idx];
				}
			});

			this.questions = questions.filter(q => q);
			this.questionAmount = this.questions.length;
		},

		buildMatrix() {
			let matrixTitle = null;
			let matrixMainQuestion = null;
			let matrixTopic = null;
			this.questions.forEach((q, idx) => {
				if (q.type != 'matrix') {
					matrixTitle = null;
					matrixMainQuestion = null;
					matrixTopic = null;
					return;
				}

				if (null === matrixTitle || matrixTitle !== q.option_1 || matrixTopic !== q.topic_id) {
					// first question
					matrixTitle = q.option_1;
					matrixMainQuestion = q;
					matrixTopic = q.topic_id;
					matrixMainQuestion.following = [];
				} else {
					// following questions
					matrixMainQuestion.following.push(q);
					delete this.questions[idx];
					// this.questions.splice(idx, 1);
				}
			});

			this.questions = this.questions.filter(q => q);
		},

		changeQuestion(callback) {
			$('.effect').fadeOut(125, () => {
				callback();
			});
		},
		showQuestion() {
			setTimeout(() => {
				$('.effect').fadeIn(125);
			}, 125);
		},

		// checkAnswers() {
		//     this.questions.forEach((q, index) => {
		//         if (index < this.counter && !Object.keys(this.answers).includes(q.id)) {
		//             this.questions.splice(index, 1);
		//         }
		//     });
		// },
		checkAnswers() {
			let addQuestion = true;
			let addRemaining = false;
			let followQuestion = '';
			let currentId = this.questions[this.counter].id;

			this.questions = [];
			this.survey.topics.forEach(t => {
				t.questions.forEach(q => {
					if (currentId === q.id) {
						addRemaining = true;
					}

					if (addRemaining === true) {
						this.questions.push(q);
					} else {
						if (followQuestion === q.id || followQuestion === q.topic_id) {
							addQuestion = true;
						}
						if (addQuestion === true) {
							this.questions.push(q);
						}

						if (Object.keys(this.answers).includes(q.id)) {
							let answerVal = this.answers[q.id];

							if (!Array.isArray(answerVal)) {
								let answerIndex = q.answers.findIndex(answered => answered.id == answerVal);

								if (answerIndex !== -1) {
									let answer = q.answers[answerIndex];

									if (answer.follow_question_id != null) {
										followQuestion = answer.follow_question_id;
										addQuestion = false;
									} else if (answer.follow_topic_id != null) {
										followQuestion = answer.follow_topic_id;
										addQuestion = false;
									}
								}
							}
						}
					}
				});
			});

			this.buildMatrix();
		},
	},
	watch: {
		questionId: function(qId) {
			this.id = qId;

			if (-1 === this.questions.findIndex(q => q.id === this.id)) {
				this.$emit('nextQuestion', 'start');
				return;
			}

			this.counter = this.questions.findIndex(q => q.id === qId);
			this.showQuestion();
		},
	},
	mounted() {
		this.buildQuestionArray();
		this.restoreAnswers();

		this.id = this.questionId;

		// invalid id
		if (-1 === this.questions.findIndex(q => q.id === this.id)) {
			this.$emit('nextQuestion', 'start');
			return;
		}

		// not all previous questions answered
		if (!this.isAllowedQuestion()) {
			this.$emit('nextQuestion', 'start');
			return;
		}

		this.counter = this.questions.findIndex(q => q.id === this.id);
		this.showQuestion();
	},
};
</script>
