<template>
	<div>
		<h1>{{ question.name }} <span v-if="question.is_required === true" style="color: darkred;">*</span></h1>
		<p>{{ question.description }}</p>

		<div class="row d-md-none">
			<div class="col bottom-text text-center">{{ question.answers[0].name }}</div>
		</div>

		<div class="row my-3">
			<div v-for="step in steps" class="col-sm-12 col-md text-center" :key="'st' + step">
				<input v-bind:id="'answer' + step" name="answer" class="range" type="radio" v-bind:value="step" v-bind:checked="givenAnswer == step" />
				<label v-bind:for="'answer' + step">{{ step }}</label>
			</div>
		</div>

		<div class="row d-md-none">
			<div class="col bottom-text text-center">{{ question.answers[1].name }}</div>
		</div>

		<div class="row d-none d-md-flex">
			<div class="col bottom-text">{{ question.answers[0].name }}</div>
			<div class="col text-right bottom-text">{{ question.answers[1].name }}</div>
		</div>

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7 question-image-container">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center mt-5">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">ZURÜCK</button>
			<button type="button" class="btn mx-2" @click="doAnswer">WEITER</button>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';

export default {
	name: 'RangeType',
	props: ['question', 'givenAnswer', 'isfirstquestion'],
	data() {
		return {
			min: 0,
			max: 0,
			steps: [],
			default: 0,
			allowsNext: false,
			answer: null,
		};
	},
	computed: {
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
	},
	mounted() {
		// console.log(this.givenAnswer);

		this.min = parseInt(this.question.option_0);
		this.max = parseInt(this.question.option_1);

		this.steps = Array(this.max - this.min + 1)
			.fill()
			.map((_, idx) => this.min + idx);

		this.default = Number(this.question.option_1 / 2).toFixed(0);
	},
	methods: {
		doAnswer() {
			let answer = $('input[name="answer"]:checked').val();
			this.$emit('answer', answer ? answer : null);
		},
	},
};
</script>
