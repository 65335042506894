<template>
	<div class="main">
		<h1>{{ question.name }} <span v-if="question.is_required === true" style="color: darkred;">*</span></h1>
		<p>{{ question.description }}</p>

		<div class="row d-md-none">
			<div class="col bottom-text text-center">
				<template v-if="!is_smiley">
					Sehr unwahrscheinlich
				</template>
				<div v-else class="text-danger mx-3 my-1">
					<img :src="baseUrl + '/media/smileys/frowning_face.gif'" style="width: 20%;"/>
				</div>
			</div>
		</div>

		<div class="row my-3" ref="stepper">
			<div v-for="step in steps" class="col-sm-12 col-md text-center" :key="'np' + step">
				<input v-bind:id="'answer' + step" name="answer" class="range" type="radio" v-bind:value="step" v-bind:checked="givenAnswer == step" v-on:click="doAnswer" />
				<label v-bind:for="'answer' + step">{{ step }}</label>
			</div>
		</div>

		<div class="row d-md-none">
			<div class="col bottom-text text-center">
				<template v-if="!is_smiley">
					Äußerst wahrscheinlich
				</template>
				<div v-else class="text-danger mx-3 my-1">
					<img :src="baseUrl + '/media/smileys/smiling_face.gif'" style="width: 20%;"/>
				</div>
			</div>
		</div>

		<div class="row d-none d-md-flex">
			<div class="col bottom-text">
				<template v-if="!is_smiley">
					Sehr unwahrscheinlich
				</template>
				<div v-else class="text-danger mx-3 my-1">
					<img :src="baseUrl + '/media/smileys/frowning_face.gif'" style="width: 100%;"/>
				</div>
			</div>
			<template v-if="is_smiley">
				<div class="col" v-for="i in 9" :key="i"></div>
			</template>
			<div class="col text-right bottom-text">
				<template v-if="!is_smiley">
					Äußerst wahrscheinlich
				</template>
				<div v-else class="text-danger mx-3 my-1">
					<img :src="baseUrl + '/media/smileys/smiling_face.gif'" style="width: 100%;" />
				</div>
			</div>
		</div>

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7 question-image-container">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center mt-5">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">{{ survey.previous }}</button>
			<button type="button" class="btn mx-2" @click="doAnswer">{{ survey.next }}</button>
		</div>
	</div>
</template>

<style scoped>
div.main > p {
	white-space: pre-line;
}
</style>

<script>
import $ from 'jquery';

export default {
	name: 'NpsType',
	props: ['survey', 'question', 'givenAnswer', 'isfirstquestion'],
	data() {
		return {
			min: 0,
			max: 10,
			steps: [],
			default: 0,
			allowsNext: false,
			answer: null,
		};
	},
	computed: {
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
		baseUrl() {
			return process.env.VUE_APP_URL;
		},
		is_smiley: function() {
			if (this.question.option_2 != null) {
				if (this.question.option_2.is_smiley)
					return true;
			}

			return false;
		}
	},
	mounted() {
		this.steps = Array(this.max - this.min + 1)
			.fill()
			.map((_, idx) => this.min + idx);

		this.default = Number(this.question.option_1 / 2).toFixed(0);
	},
	methods: {
		doAnswer() {
			let answer = $('input[name="answer"]:checked').val();
			this.$emit('answer', answer ? answer : null);
		},
	},
};
</script>
