<template>
	<div class="main">
		<h1>{{ question.name }} <span v-if="question.is_required === true" style="color: darkred;">*</span></h1>
		<p>{{ question.description }}</p>

		<div v-for="(item, index) in question.answers" :key="'rt' + index + item.id">
			<input v-bind:id="'answer' + index" name="answer" type="radio" v-bind:value="item.id" v-bind:checked="answer === item.id" v-on:click="doAnswer(true)" />
			<label v-bind:for="'answer' + index">{{ item.name }}</label>
		</div>
		<div class="mt-5" v-if="question.option_0 === '1'" v-on:click="unselectRadios">
			<input name="answer" type="radio" value="other" v-bind:checked="answer === 'other'" />
			<input-contenteditable :placeholder="survey.others" name="other" class="editable" v-model="other" _is="label" v-on:click="unselectRadios" @focus="unselectRadios" @change="unselectRadios" />
		</div>
		<div class="mt-5" v-if="question.option_1 === '1'" v-on:click="selectNoAnswer">
			<input name="answer" type="radio" value="noanswer" v-bind:checked="answer === 'noanswer'" />
			<label v-bind:for="'answerno'">{{ survey.no_answer }}</label>
		</div>

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7 question-image-container">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center mt-3">
			<button v-if="isfirstquestion === false" type="button" class="btn" @click="$emit('previousQuestion')">{{ survey.previous }}</button>
			<button type="button" class="btn" @click="doAnswer(false)">{{ survey.next }}</button>
		</div>
	</div>
</template>

<style scoped>
div.main > p {
	white-space: pre-line;
}
</style>

<script>
import $ from 'jquery';
import * as othersTranslation from '@/core/config/others_translation.json';

export default {
	name: 'RadioType',
	props: ['survey', 'question', 'givenAnswer', 'lang', 'isfirstquestion'],
	data() {
		return {
			answer: null,
			answers: [],
			other: '',
		};
	},
	mounted() {
		if (this.givenAnswer != null) {
			let arr = this.question.answers.filter(value => { return value.id === this.givenAnswer; });
			if (arr.length === 0) {
				if (this.givenAnswer === 'noanswer')
					this.answer = 'noanswer';
				else {
					this.other = this.givenAnswer;
					this.answer = 'other';
				}
			}
			else {
				this.answer = this.givenAnswer;
			}
		}
	},
	computed: {
		getOthersText: function() {
			if (othersTranslation.default.length > 0) {
				let trans = othersTranslation.default.filter(value => { return value.symbol === this.lang; });
				if (trans != null && trans.length === 1) {
					return trans[0].text;
				} else {
					return 'others';
				}
			}

			return 'others';
		},
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
	},
	methods: {
		doAnswer(is_option) {
			let answer = null;
			if (this.answer === 'other' && is_option === false) {
				answer = this.other;
			}
			else if (this.answer === 'noanswer') {
				answer = 'noanswer';
			}
			else {
				if ($('input[name="answer"]:checked').length > 0) {
					answer = $('input[name="answer"]:checked').val();
				}
			}

			this.$emit('answer', answer ? answer : null);
		},
		unselectRadios: function() {
			if (this.answer !== 'other') {
				$('input[name="answer"]:checked').each((index) => {
					$('input[name="answer"]:checked')[index].checked = false;
				});

				this.answer = 'other';
			}
		},
		selectNoAnswer: function() {
			if (this.answer !== 'noanswer') {
				$('input[name="answer"]:checked').each((index) => {
					$('input[name="answer"]:checked')[index].checked = false;
				});

				this.answer = 'noanswer';
				this.doAnswer(false);
			}
		},
	},
};
</script>
