<template>
	<div class="main">
		<h1>{{ question.name }} <span v-if="question.is_required === true" style="color: darkred;">*</span></h1>
		<p>{{ question.description }}</p>

		<div class="row">
			<div v-for="(answer, index) in question.answers" v-bind:class="{ 'col-12': true, 'col-md-6': question.answers.length > 6 }" :key="'aa' + index + answer.id">
				<input v-bind:id="'answer' + index" name="answer" type="checkbox" v-bind:value="answer.id" v-bind:checked="answers && answers.indexOf(answer.id) > -1" />
				<label v-bind:for="'answer' + index">
					{{ answer.name }}
				</label>
			</div>
			<div v-on:click="setOther" class="col-12 mt-5" v-if="question.option_0 === '1'">
				<input name="answerother" type="checkbox" value="other" v-bind:checked="answers && answers.indexOf('other') > -1" />
				<input-contenteditable :placeholder="survey.others" name="other" class="editable" v-model="other" _is="label" />
			</div>
<!--			<div class="col-12 mt-5" v-if="question.option_1 === '1'" v-on:click="selectNoAnswer">-->
<!--				<input name="noanswer" type="checkbox" value="noanswer" v-bind:checked="answers && answers.indexOf('noanswer') > -1" />-->
<!--				<label v-bind:for="'answerno'">Keine Angabe</label>-->
<!--			</div>-->
		</div>

		<div v-if="question.image !== '' && question.image != null" :class="imgClass" class="my-7">
			<img v-bind:src="storageUrl + '/questionPicture/' + question.image" class="img-fluid" style="max-height: 15vh;" />
			<p>{{ question.image_title }}</p>
		</div>

		<div class="text-center mt-3">
			<button v-if="isfirstquestion === false" type="button" class="btn mx-2" @click="$emit('previousQuestion')">{{ survey.previous }}</button>
			<button type="button" class="btn mx-2" @click="doAnswer">{{ survey.next }}</button>
		</div>
	</div>
</template>
<style scoped>
div.main > p {
	white-space: pre-line;
}
</style>
<script>
import $ from 'jquery';
import * as othersTranslation from "@/core/config/others_translation.json";

export default {
	name: 'CheckboxType',
	props: ['survey', 'question', 'givenAnswer', 'lang', 'isfirstquestion'],
	data() {
		return {
			answers: [],
			other: '',
		};
	},
	mounted() {
		if (this.givenAnswer != null) {
			this.givenAnswer.forEach(givenAns => {
				let arr = this.question.answers.filter(value => { return value.id === givenAns; });
				if (arr.length === 0) {
					this.other = givenAns;
					this.answers.push('other');
				}
				else {
					this.answers.push(givenAns);
				}
			});
		}
	},
	computed: {
		getOthersText: function() {
			if (othersTranslation.default.length > 0) {
				let trans = othersTranslation.default.filter(value => { return value.symbol === this.lang; });
				if (trans != null && trans.length === 1) {
					return trans[0].text;
				} else {
					return 'others';
				}
			}

			return 'others';
		},
		storageUrl: function() {
			return process.env.VUE_APP_URL_STORAGE;
		},
		imgClass: function() {
			if (this.question.image_align === 'l') {
				return 'text-left';
			}
			else if (this.question.image_align === 'c') {
				return 'text-center';
			}
			else if (this.question.image_align === 'r') {
				return 'text-right';
			}
			else {
				return '';
			}
		},
	},
	methods: {
		setOther: function() {
			let arr = this.answers.filter(value => { return value === 'other'; });
			if (arr.length > 0) {
				this.answers = this.answers.filter(value => { return value !== 'other'; });
			}
			else {
				this.answers.push('other');
			}
		},
		// selectNoAnswer: function() {
		// 	$('input[name="answer"]:checked').each((index) => {
		// 		$('input[name="answer"]:checked')[index].checked = false;
		// 	});
		//
		// 	this.answers = ['noanswer'];
		// },
		doAnswer() {
			let answers = [];
			if ($('input[name="answer"]:checked').length > 0) {
				answers = $(this.$el)
					.find('input:checked')
					.map((idx, x) => $(x).val())
					.get();
			}

			if ($('input[name="answerother"]:checked').length > 0) {
				if (this.other !== '') {
					answers.push(this.other);
				}
			}

			this.$emit('answer', answers);
		},
	},
};
</script>
