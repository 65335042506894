<template>
	<div>
		<div class="row justify-content-center">
			<div class="col col-lg-8 text-center first-step p-5">
				<template v-if="error === 'reported'">
					<h1>Sie haben schon an dieser Umfrage teilgenommen.</h1>
				</template>
				<template v-if="survey.topics != null && error === ''">
					<h1>{{ survey.title }}</h1>
					<h2>{{ survey.sub_title }}</h2>

					<p>{{ survey.description }}</p>

					<button type="button" class="btn mt-5 mb-5" v-on:click="nextStep">{{ survey.start }}</button>
				</template>

			</div>
		</div>
	</div>
</template>

<style scoped>
h1 {
	font-weight: 800;
	font-size: 3rem;
}

p {
	font-weight: 400;
	white-space: pre-line;
}

.first-step {
	word-break: break-word;
}
</style>

<script>
export default {
	name: 'SubscriberStart',
	props: {
		survey: Object,
		error: String,
	},
	methods: {
		nextStep() {
			let firstId = this.$props.survey.topics[0].questions[0].id;
			localStorage.removeItem('answers');

			this.$emit('nextQuestion', firstId);
		},
	},
};
</script>
